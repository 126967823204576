import React from "react";
import { List, Datagrid, TextField, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, required, maxLength } from 'react-admin';
import {Box} from '@mui/material';
import ImageArrayField from "../components/ImageArrayField";
import MyMapPicker from "../components/MyMapPicker";
export const OutletsList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="owner" />
            <TextField source="address" />
            <TextField source="phone" />
        </Datagrid>
    </List>
);
export const OutletsEdit = props => {
    //const latitudeFieldProps=useController({name:"latitude"}).field;
    //const longitudeFieldProps=useController({name:"longitude"}).field;
    //console.log("outlets edit");
    return (
    <Edit {...props}>
        <SimpleForm>
            <Box style={{display:"flex",width:"100%"}}>
                <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:8 }}>
                    <TextInput source="name" validate={[maxLength(128),required()]}/>
                    <TextInput source="owner" validate={[maxLength(128)]}/>
                    <ImageArrayField source="photos"/>
                    <ReferenceInput source="user.id" reference="User">
                        <SelectInput optionText="name" label="Sales"/>
                    </ReferenceInput>
                </Box>
                <Box style={{display:"flex",flexDirection:"column", width:"50%", paddingLeft:8}}>
                    <TextInput source="address" validate={[maxLength(256),required()]}/>
                    <TextInput source="phone" validate={[maxLength(64)]}/>
                    <MyMapPicker source="latitude" onChangeLocation={(lat,lng)=>{
                        console.log(lat,lng);
                        //latitudeFieldProps.onChange(lat);
                        //longitudeFieldProps.onChange(lng);
                    }}/>
                </Box>
            </Box>
        </SimpleForm>
    </Edit>);
};