import React from "react";
import {List,Datagrid,TextField,ReferenceField} from "react-admin";
import { useFormContext,useWatch } from 'react-hook-form';
import {Button,Dialog,DialogTitle} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MyDatagridQtyInput from "./MyDatagridQtyInput";
import MyDatagridDeleteButton from "./MyDatagridDeleteButton";
const MyProductSelector = (props) => {
    const {source}=props;
    const {getValues,setValue}=useFormContext();
    const record=useWatch({name:source})||[];
    const status=getValues("status");
    const [open, setOpen] = React.useState(false);
    const onDataChange=()=>{
        const recordAmounts={
            subtotal:0,
            tax:0,
            total:0
        }
        for(var i=0;i<record.length;i++){
            recordAmounts.subtotal+=record[i].subtotal;
        }
        recordAmounts.tax=recordAmounts.subtotal*0.11;
        recordAmounts.total=recordAmounts.subtotal+recordAmounts.tax;
        setValue("amounts.subtotal",recordAmounts.subtotal);
        setValue("amounts.tax",recordAmounts.tax);
        setValue("amounts.total",recordAmounts.total);
    }
    return (<div>
        {status==="Draft" && <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={()=>setOpen(true)}>Add</Button>}
        {record.length>0 && <Datagrid
            data={record}
            total={record.length}
            isLoading={false}
            sort={{ field: 'id', order: 'asc' }}
            bulkActionButtons={false}>
            <ReferenceField source="product.id" reference="Product">
                <TextField source="name" />
            </ReferenceField>
            {status==="Draft" && <MyDatagridQtyInput source="qty" data={record} datagridSource={source} onDataChange={onDataChange}/>}
            {status!=="Draft" && <TextField source="qty"/>}
            <TextField source="price"/>
            <TextField source="discountPrice"/>
            <TextField source="subtotal"/>
            {status==="Draft" && <MyDatagridDeleteButton data={record} datagridSource={source} onDataChange={onDataChange}/>}
        </Datagrid>}
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}>
            <DialogTitle>Select Product</DialogTitle>
            <List resource="Product" hasCreate={false} exporter={false}>
                <Datagrid bulkActionButtons={false} rowClick={(id,resource,row)=>{
                    setOpen(false);
                    var found=false;
                    for(var i=0;i<record.length;i++){
                        if(record[i].product.id===row.id){
                            found=true;
                            record[i].qty++;
                            record[i].price=row.price;
                            record[i].discountPrice=row.price;
                            record[i].subtotal=record[i].qty*record[i].discountPrice;
                            break;
                        }
                    }
                    if(!found){
                        record.push({
                            id:row.id,
                            product:{
                                id:row.id
                            },
                            qty:1,
                            price:row.price,
                            discountPrice:row.price,
                            subtotal:row.price
                        });
                    }
                    setValue(source,record);
                    onDataChange();
                }}>
                    <TextField source="code"/>
                    <TextField source="name"/>
                    <TextField source="qty"/>
                    <TextField source="price"/>
                    <TextField source="discountPrice"/>
                </Datagrid>
            </List>
        </Dialog>
    </div>)
}
export default MyProductSelector;