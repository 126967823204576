import React,{useState} from 'react';
import { TextField, Container, Button, ThemeProvider, Box, Typography, Card, CardContent, CardActions } from '@mui/material';
import {useTheme,useNotify} from 'react-admin';
import {resetPassword} from '../graphqlClient';
export const ResetPassword = () => {
    const [validation,setValidation]=useState({email:{error:false,message:""},snackbar:{open:false,type:"success",message:""}});
    const theme=useTheme()[0]||{};
    const notify=useNotify();
    const submit=(event)=>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const newValidation={...validation};
        if(formData.get("email")===""){
            newValidation.email={error:true,message:"Please enter an email"};
        } else {
            newValidation.email={error:false,message:""};
        }
        setValidation(newValidation);
        if(!newValidation.email.error){
            resetPassword(formData.get("email"))
            .then((response)=>{
                notify("Please check your email for reset password instructions",{type:"success"});
            })
            .catch((error)=>{
                console.log("error",error.message);
                notify(error.message,{type:"error"});
            })
            .finally(()=>setValidation({...newValidation}));
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Box component="form" onSubmit={submit} noValidate
                    sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Card>
                        <CardContent>
                            <Typography component="h1" variant="h5">
                                Reset Password
                            </Typography>
                            <TextField type="email"
                                label="Email:"
                                variant="filled"
                                margin="normal"
                                name="email"
                                error={validation.email.error}
                                helperText={validation.email.message}
                                required fullWidth autoFocus/>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" type="submit">Reset Password</Button>
                        </CardActions>
                    </Card>
                </Box>
            </Container>
        </ThemeProvider>
    )
};
