import React from "react";
import { List, Datagrid, TextField, Create, Edit, SimpleForm, TextInput, NumberInput, BooleanInput, ReferenceArrayInput, SelectArrayInput, AutocompleteArrayInput, required, maxLength } from 'react-admin';
import {Box} from '@mui/material';
import ImageArrayField from "../components/ImageArrayField";
import MyBooleanField from "../components/MyBooleanField";
export const ProductsList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="price" />
            <MyBooleanField source="active"/>
        </Datagrid>
    </List>
);
export const ProductsCreate = props => {
    const tags=[];
    return (
    <Create {...props}>
        <SimpleForm initialValues={{active:true,description:"",photos:[],tags:[]}}>
            <BooleanInput source="active"/>
            <Box style={{display:"flex",width:"100%"}}>
                <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:8 }}>
                    <TextInput source="code" validate={[maxLength(64),required()]}/>
                    <TextInput source="name" validate={[maxLength(64),required()]}/>
                </Box>
                <Box style={{display:"flex",flexDirection:"column", width:"50%", paddingLeft:8}}>
                    <TextInput source="description" validate={[maxLength(65536)]} multiline rows={5}/>
                </Box>
            </Box>
            <Box style={{display:"flex",width:"100%"}}>
                <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:8 }}>
                    <NumberInput source="price" validate={[required()]} min="0"/>
                    <ReferenceArrayInput source="categories" reference="Category">
                        <SelectArrayInput optionText="name"/>
                    </ReferenceArrayInput>
                </Box>
                <Box style={{display:"flex",flexDirection:"column", width:"50%", paddingLeft:8}}>
                    <NumberInput source="qty" validate={[required()]} min="0"/>
                    <AutocompleteArrayInput source="tags" choices={tags} createItemLabel="Add tag" onCreate={(e) => {
                        const newTag = { id: e, name: e };
                        tags.push(newTag);
                        return newTag;
                    }} 
                    format={value => {
                        for(var i=0;i<value.length;i++){
                            var found=false;
                            for(var j=0;j<tags.length;j++){
                                if(tags[j].id===value[i]){
                                    found=true;
                                    break;
                                }
                            }
                            if(!found){
                                tags.push({id:value[i],name:value[i]});
                            }
                        }
                        return value;
                    }}/>
                </Box>
            </Box>
            <ImageArrayField source="photos"/>
        </SimpleForm>
    </Create>
    );
}
export const ProductsEdit = props => {
    const tags=[];
    return (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="active"/>
            <Box style={{display:"flex",width:"100%"}}>
                <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:8 }}>
                    <TextInput source="code" validate={[maxLength(64),required()]}/>
                    <TextInput source="name" validate={[maxLength(64),required()]}/>
                </Box>
                <Box style={{display:"flex",flexDirection:"column", width:"50%", paddingLeft:8}}>
                    <TextInput source="description" validate={[maxLength(65536)]} multiline rows={5}/>
                </Box>
            </Box>
            <Box style={{display:"flex",width:"100%"}}>
                <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:8 }}>
                    <NumberInput source="price" validate={[required()]} min="0"/>
                    <ReferenceArrayInput source="categories" reference="Category">
                        <SelectArrayInput optionText="name"/>
                    </ReferenceArrayInput>
                </Box>
                <Box style={{display:"flex",flexDirection:"column", width:"50%", paddingLeft:8}}>
                    <NumberInput source="qty" validate={[required()]} min="0"/>
                    <AutocompleteArrayInput source="tags" choices={tags} createItemLabel="Add tag" onCreate={(e) => {
                        const newTag = { id: e, name: e };
                        tags.push(newTag);
                        return newTag;
                    }} 
                    format={value => {
                        for(var i=0;i<value.length;i++){
                            var found=false;
                            for(var j=0;j<tags.length;j++){
                                if(tags[j].id===value[i]){
                                    found=true;
                                    break;
                                }
                            }
                            if(!found){
                                tags.push({id:value[i],name:value[i]});
                            }
                        }
                        return value;
                    }}/>
                </Box>
            </Box>
            <ImageArrayField source="photos"/>
        </SimpleForm>
    </Edit>);
}