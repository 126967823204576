import React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, BooleanField } from 'react-admin';

const MyBooleanField = (props) => {
    var record = useRecordContext(props);
    if(record[props.source]){
        record[props.source]=true;
    } else {
        record[props.source]=false;
    }
    return <BooleanField record={record} source={props.source}/>;
}

MyBooleanField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string
};

export default MyBooleanField;