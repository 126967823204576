import React,{useState} from 'react';
import { TextField, Container, Button, ThemeProvider, Box, Typography, Card, CardContent, CardActions, Snackbar, Alert } from '@mui/material';
import {createPassword} from '../graphqlClient';
import { useParams } from "react-router-dom";
import {useTheme,useRedirect} from 'react-admin';
export const CreatePassword = () => {
    let params = useParams();
    const redirect = useRedirect();
    const [validation,setValidation]=useState({newPassword:{error:false,message:""},retypePassword:{error:false,message:""},snackbar:{open:false,type:"success",message:""}});
    const theme=useTheme()[0]||{};
    const submit=(event)=>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const newValidation={...validation};
        if(formData.get("newPassword")===""){
            newValidation.newPassword={error:true,message:"Please enter new password"};
        } else {
            newValidation.newPassword={error:false,message:""};
        }
        if(formData.get("retypePassword")===""){
            newValidation.retypePassword={error:true,message:"Please enter re-type password"};
        } else {
            if(formData.get("newPassword")!==formData.get("retypePassword")){
                newValidation.retypePassword={error:true,message:"Re-type password is not same"};
            } else {
                newValidation.retypePassword={error:false,message:""};
            }
        }
        setValidation(newValidation);
        if(!newValidation.newPassword.error && !newValidation.retypePassword.error){
            createPassword(params.token,formData.get("newPassword"))
            .then((response)=>{
                newValidation.snackbar.open=true;
                newValidation.snackbar.type="success";
                newValidation.snackbar.message="Password created successfully";
            })
            .catch((error)=>{
                console.log("error",error.message);
                newValidation.snackbar.open=true;
                newValidation.snackbar.type="error";
                newValidation.snackbar.message=error.message;
            })
            .finally(()=>setValidation({...newValidation}));
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        validation.snackbar.open=false;
        setValidation({...validation});
        if(validation.snackbar.type==="success"){
            redirect("/login");
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Box component="form" onSubmit={submit} noValidate
                    sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Card>
                        <CardContent>
                            <Typography component="h1" variant="h5">
                                Create Password
                            </Typography>
                            <TextField type="password"
                                label="New password:"
                                variant="filled"
                                margin="normal"
                                name="newPassword"
                                error={validation.newPassword.error}
                                helperText={validation.newPassword.message}
                                required fullWidth autoFocus/>
                            <TextField type="password"
                                label="Re-type password:"
                                variant="filled"
                                margin='normal'
                                name="retypePassword"
                                error={validation.retypePassword.error}
                                helperText={validation.retypePassword.message}
                                required fullWidth/>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" type="submit">Create Password</Button>
                        </CardActions>
                    </Card>
                    <Snackbar
                        anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                        open={validation.snackbar.open}
                        autoHideDuration={5000}
                        onClose={handleClose}
                    >
                        <Alert onClose={handleClose} severity={validation.snackbar.type}>{validation.snackbar.message}</Alert>
                    </Snackbar>
                </Box>
            </Container>
        </ThemeProvider>
    )
};
