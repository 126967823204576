import React,{useState} from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import MapPicker from 'react-google-map-picker';
//import { useController } from 'react-hook-form';
const MyMapPicker = (props) => {
    //const [count, setCount] = useState(0);
    //const latitudeFieldProps=useController({name:"latitude"});
    //console.log(latitudeFieldProps);
    //const longitudeFieldProps=useController({name:"longitude"});
    var record = useRecordContext(props);
    const [zoom, setZoom] = useState(16);
    function handleChangeZoom (newZoom){
        setZoom(newZoom);
    }
    //const a=useInput(props);
    //console.log("use input");
    //console.log(a);
    /*if(count<1){
        //setCount(count+1);
        //
    }*/
    //console.log(props);
    //console.log(record);
    //return (<div></div>);
    return (<MapPicker mapTypeId="roadmap"
        defaultLocation={{lat:record.latitude||0,lng:record.longitude||0}}
        style={{height:'300px'}}
        apiKey='AIzaSyCPwNpCQQ_WeZOYW5nmB9zPFLOiHyv33HU'
        zoom={zoom}
        onChangeZoom={handleChangeZoom}
        onChangeLocation={props.onChangeLocation}/>);
}

MyMapPicker.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string
};
export default React.memo(MyMapPicker,(prevProps,nextProps)=>{
    if(prevProps.record.latitude===nextProps.record.latitude && prevProps.record.longitude===nextProps.record.longitude){
        return true;
    }
    return false;
});