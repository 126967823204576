import React from "react";
import { List, Datagrid, TextField, Create, Edit, SimpleForm, TextInput, BooleanInput, required, maxLength } from 'react-admin';
import ImageArrayField from "../components/ImageArrayField";
import MyBooleanField from "../components/MyBooleanField";
export const CategoriesList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <MyBooleanField source="active"/>
        </Datagrid>
    </List>
);
export const CategoriesCreate = props => (
    <Create {...props}>
        <SimpleForm defaultValues={{active:true,photos:[]}}>
            <BooleanInput source="active"/>
            <TextInput source="name" validate={[maxLength(64),required()]}/>
            <ImageArrayField source="photos"/>
        </SimpleForm>
    </Create>
);
export const CategoriesEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="active"/>
            <TextInput source="name" validate={[maxLength(64),required()]}/>
            <ImageArrayField source="photos"/>
        </SimpleForm>
    </Edit>
);