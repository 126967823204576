import React from "react";
import { List, Datagrid, TextField, ReferenceField, DateField, Create, Edit, SimpleForm, ReferenceInput, SelectInput, DateInput, TextInput, Toolbar, SaveButton,useEditContext } from 'react-admin';
import MyProductSelector from "../components/MyProductSelector";
import MyDebugField from "../components/MyDebugField";
import {Box} from '@mui/material';
export const OrdersList = props => (
    <List {...props} bulkActionButtons={false} sort={{ field: 'created_at', order: 'DESC' }} undoable={false}>
        <Datagrid rowClick="edit">
            <TextField source="orderRef" />
            <ReferenceField source="outlet.id" reference="Outlet">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="status" />
            <TextField source="paymentStatus" />
            <TextField source="amounts.total" />
            <DateField source="createdAt" />
            <DateField source="dueDate" />
        </Datagrid>
    </List>
);
const transformCreateOrder = (data) => {
    const orderDetails=[];
    for(var i=0;i<data.details.length;i++){
        orderDetails.push({
            id:data.details[i].id,
            productId:data.details[i].product.id,
            qty:data.details[i].qty
        });
    }
    data.dueDate=new Date(data.dueDate);
    data.orderDetails=orderDetails;
    return {...data};
};
const CreateToolbar = () => {
    return (
        <Toolbar>
            <Box mr={1}>
                <SaveButton type="button" transform={(data)=>{ data.status="Submitted"; return transformCreateOrder(data)}} />
            </Box>
            <Box>
                <SaveButton type="button" alwaysEnable={true} label="Save as draft" transform={transformCreateOrder}/>
            </Box>
        </Toolbar>
    );
};
const EditToolbar = () => {
    const { record } = useEditContext();
    return (
        <Toolbar>
            {record.status==="Draft" && <Box mr={1}>
                <SaveButton type="button" alwaysEnable={true} transform={(data)=>{ data.status="Submitted"; return transformCreateOrder(data)}} />
            </Box>}
            {record.status==="Draft" && <Box mr={1}>
                <SaveButton type="button" alwaysEnable={true} label="Save as draft" transform={transformCreateOrder}/>
            </Box>}
            {record.status==="Submitted" && <Box>
                <SaveButton type="button" alwaysEnable={true} label="Processing" transform={(data)=>{ data.status="Processing"; return transformCreateOrder(data)}} />
            </Box>}
        </Toolbar>
    );
};
export const OrdersCreate = props => {
    const transformCreateOrder = (data) => {
        const orderDetails=[];
        for(var i=0;i<data.orderDetails.length;i++){
            orderDetails.push({
                productId:data.orderDetails[i].productId,
                qty:data.orderDetails[i].qty
            });
        }
        data.dueDate=new Date(data.dueDate);
        data.orderDetails=orderDetails;
        data.amounts=null;
        return {...data};
    };
    const onListChange=(e)=>{}
    return (<Create {...props} transform={transformCreateOrder}>
        <SimpleForm toolbar={<CreateToolbar />} defaultValues={{details:[],dueDate:Date.now(),status:"Draft",remarks:"",amounts:{subtotal:0,tax:0,total:0}}}>
            <Box style={{display:"flex",width:"100%"}}>
                <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:8 }}>
                    <ReferenceInput source="outletId" reference="Outlet">
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                </Box>
                <Box style={{display:"flex",flexDirection:"column", width:"50%", paddingLeft:8}}>
                    <DateInput source="dueDate"/>
                </Box>
            </Box>
            <TextInput source="remarks"/>
            <MyProductSelector source="details"/>
            <TextInput source="amounts.subtotal" disabled/>
            <TextInput source="amounts.tax" disabled/>
            <TextInput source="amounts.total" disabled/>
            <MyDebugField/>
        </SimpleForm>
    </Create>);
};
export const OrdersEdit = props => (
    <Edit {...props} transform={transformCreateOrder}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <Box style={{display:"flex",width:"100%"}}>
                <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:8 }}>
                    <ReferenceInput source="outlet.id" reference="Outlet">
                        <SelectInput optionText="name"/>
                    </ReferenceInput>
                </Box>
                <Box style={{display:"flex",flexDirection:"column", width:"50%", paddingLeft:8}}>
                    <DateInput source="dueDate"/>
                </Box>
            </Box>
            <TextInput source="remarks"/>
            <MyProductSelector source="details"/>
            <TextInput source="amounts.subtotal" disabled/>
            <TextInput source="amounts.tax" disabled/>
            <TextInput source="amounts.total" disabled/>
            <MyDebugField/>
        </SimpleForm>
    </Edit>
);