import React from "react";
import { List, Datagrid, TextField, Create, Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
export const UsersList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="email" />
            <TextField source="name" />
            <TextField source="status" />
        </Datagrid>
    </List>
);
export const UsersCreate = props => (
    <Create {...props}>
        <SimpleForm initialValues={{status:"Active"}}>
            <TextInput source="email" validate={[required()]} />
            <TextInput source="name" validate={[required()]}/>
            <SelectInput source="role" choices={[
                {id:"Account_Admin",name:"Account Admin"},
                {id:"Sales",name:"Sales"},
                {id:"Operation",name:"Operation"},
            ]} validate={[required()]}/>
            <SelectInput source="status" choices={[
                {id:"Active",name:"Active"},
                {id:"Inactive",name:"Inactive"}
            ]} validate={[required()]}/>
        </SimpleForm>
    </Create>
);
export const UsersEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="email" validate={[required()]} />
            <TextInput source="name" validate={[required()]}/>
            <SelectInput source="role" choices={[
                {id:"Account_Admin",name:"Account Admin"},
                {id:"Sales",name:"Sales"},
                {id:"Operation",name:"Operation"},
            ]} validate={[required()]}/>
            <SelectInput source="status" choices={[
                {id:"Active",name:"Active"},
                {id:"Inactive",name:"Inactive"}
            ]} validate={[required()]}/>
        </SimpleForm>
    </Edit>
);