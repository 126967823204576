import React from "react";
import { List, Datagrid, TextField, Create, Edit, SimpleForm, TextInput, PasswordInput, BooleanInput, required, maxLength } from 'react-admin';
export const SalesmenList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="name" />
            <TextField source="phone" />
        </Datagrid>
    </List>
);
export const SalesmenCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <BooleanInput source="active" parse={v=>v?"1":"0"}/>
            <TextInput source="username" validate={[maxLength(64),required()]}/>
            <PasswordInput source="password" validate={[maxLength(32),required()]}/>
            <TextInput source="name" validate={[maxLength(128),required()]}/>
            <TextInput source="phone" validate={[maxLength(64)]}/>
        </SimpleForm>
    </Create>
);
export const SalesmenEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="active" format={v=>v==="1"} parse={v=>v?"1":"0"} />
            <TextInput source="username" validate={[maxLength(64),required()]}/>
            <TextInput source="name" validate={[maxLength(128),required()]}/>
            <TextInput source="phone" validate={[maxLength(64)]}/>
        </SimpleForm>
    </Edit>
);