import React from 'react';
import Dropzone from 'react-dropzone';
import { useController } from 'react-hook-form';
import {useGetIdentity} from 'react-admin';
import {upload} from '../graphqlClient';
import {Box,Button,IconButton} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

const ImageArrayField = (props) => {
    const {source}=props;
    const fieldProps=useController({name:source}).field;
    const { identity } = useGetIdentity();
    const record={};
    const deleteImage=(idx)=>{
        record[source].splice(idx,1);
        fieldProps.onChange([...record[source]]);
    }
    record[source]=fieldProps.value;
    return (
    <div>
        <Dropzone accept="image/jpeg" maxSize={1024*1024} onDrop={(acceptedFiles)=>{
            if(acceptedFiles.length>0){
                upload(identity.accountId,acceptedFiles[0]).then(response => {
                    if(record[source]){
                        record[source].push(response.path);
                    } else {
                        record[source]=[response.path];
                    }
                    fieldProps.onChange([...record[source]]);
                }).catch(
                    error => console.log(error) // Handle the error response object
                )
            }
        }}>
            {({getRootProps, getInputProps, open}) => {
                return (
                    <div {...getRootProps()}>
                        <p>Photos:</p>
                        <input {...getInputProps()} />
                        <Button variant="contained" startIcon={<SaveIcon/>} onClick={open}>
                            Browse File
                        </Button>
                    </div>
                )
            }}
        </Dropzone>
        <Box style={{display:"flex",width:"100%",flexWrap:"wrap"}}>
        {record && record[source] && record[source].map((item,idx) => (
            <Box style={{display:"flex",flexDirection:"column",width:"50%",paddingRight:16,paddingTop:16,position:"relative" }}>
                <img src={item} key={idx} alt={record.name} style={{width:"100%"}}/>
                <IconButton style={{position:"absolute",right:0,top:0,padding:"16px 16px 0px 0px"}} onClick={()=>deleteImage(idx)} color="error">
                    <DeleteIcon/>
                </IconButton>
            </Box>
        ))}
        </Box>
    </div>);
};
export default ImageArrayField;