import React from "react";
import { Admin, Resource, CustomRoutes, Notification } from 'react-admin';
import buildGraphQLProvider from 'ra-data-graphql-simple';
import {authLink,httpLink,authProvider} from './graphqlClient';
import {SalesmenList, SalesmenCreate, SalesmenEdit} from './screens/salesmen';
import {OutletsList, OutletsEdit} from './screens/outlets';
import {CategoriesList, CategoriesCreate, CategoriesEdit} from './screens/categories';
import {ProductsList, ProductsCreate, ProductsEdit} from './screens/products';
import {UsersList, UsersCreate, UsersEdit} from './screens/users';
import {OrdersList, OrdersCreate, OrdersEdit} from './screens/orders';
import {SalesTrackingsList} from './screens/salesTrackings';
import {CreatePassword} from './screens/createPassword';
import {ResetPassword} from './screens/resetPassword';
import {Route} from 'react-router-dom';
import {Login} from './screens/login';
const App = () => {
  const [dataProvider, setDataProvider] = React.useState(null);
  React.useEffect(() => {
      buildGraphQLProvider({ clientOptions: { link: authLink.concat(httpLink) } })
          .then(graphQlDataProvider => setDataProvider(() => graphQlDataProvider));
  }, []);

  if (!dataProvider) {
      return (<div>Loading < /div>);
  }

  return (<Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={<Login/>}>
    <Resource name="Product" list={ProductsList} create={ProductsCreate} edit={ProductsEdit}/>
    <Resource name="Category" list={CategoriesList} create={CategoriesCreate} edit={CategoriesEdit}/>
    <Resource name="Outlet" list={OutletsList} edit={OutletsEdit}/>
    <Resource name="Order" list={OrdersList} create={OrdersCreate} edit={OrdersEdit}/>
    <Resource name="User" list={UsersList} create={UsersCreate} edit={UsersEdit}/>
    <Resource name="SalesTracking" list={SalesTrackingsList}/>
    <Resource name="salesmen" list={SalesmenList} create={SalesmenCreate} edit={SalesmenEdit}/>
    <CustomRoutes noLayout>
        <Route path="/ResetPassword" element={<ResetPassword/>}/>
        <Route path="/CreatePassword/:token" element={<CreatePassword/>}/>
    </CustomRoutes>
  </Admin>);
}
export default App;