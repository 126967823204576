import React from 'react';
import { useRecordContext } from 'react-admin';
import { useWatch,useFormContext } from 'react-hook-form';
import {TextField} from '@mui/material';
const MyDatagridQtyInput = (props) => {
    const record = useRecordContext();
    const {source,data,datagridSource}=props;
    const {setValue}=useFormContext();
    var idx=0;
    for(var i=0;i<data.length;i++){
        if(data[i].product.id===record.product.id){
            idx=i;
            break;
        }
    }
    const qty=useWatch({name:datagridSource+"["+idx+"]."+source});
    return (<TextField value={qty} type="number" min="0" onChange={(e)=>{
        var v=0;
        if(e.target.value!==""){
            v=parseInt(e.target.value);
        }
        data[idx].qty=v;
        data[idx].subtotal=v*data[idx].discountPrice;
        console.log(data[idx].subtotal);
        setValue(datagridSource+"["+idx+"]."+source,data[idx].qty);
        setValue(datagridSource+"["+idx+"].subtotal",data[idx].subtotal);
        props.onDataChange();
    }}/>);
}
export default MyDatagridQtyInput;