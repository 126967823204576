import { ApolloClient, InMemoryCache, gql, createHttpLink} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

const server="https://api.staging.dms.sdpmobile.net";

const client = new ApolloClient({
    uri: server+"/query",
    cache: new InMemoryCache()
});
export const createPassword=(token,password)=>{
    return client.mutate({
        mutation:gql `
            mutation createPassword{
                createPassword(token:"${token}",password:"${password}")
            }
        `
    }).then(result => {
        Promise.resolve()
    }).catch(error => Promise.reject(error));
};
export const resetPassword=(email)=>{
    return client.mutate({
        mutation:gql `
            mutation resetPassword{
                resetPassword(email:"${email}")
            }
        `
    }).then(result => {
        Promise.resolve()
    }).catch(error => Promise.reject(error));
};
export const httpLink = createHttpLink({
  uri: server+"/query",
});
export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      "access-token": token || "",
    }
  }
});
export const authProvider = {
    login: (params) =>{
        return client.mutate({
            mutation:gql `
                mutation login{
                    login(email:"${params.username}",password:"${params.password}"){
                        token,
                        profile{
                            id,
                            accountId,
                            email,
                            name,
                            role,
                            status
                        }
                    }
                }
            `
        }).then(result => {
            localStorage.setItem("token",result.data.login.token);
            localStorage.setItem("profile",JSON.stringify(result.data.login.profile));
            Promise.resolve()
        }).catch(error => Promise.reject(error));
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('profile');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: params => localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        return Promise.resolve();
    },
    // get the user's profile
    getIdentity: () => {
        const profile=JSON.parse(localStorage.getItem('profile'));
        profile.fullName=profile.name;
        return Promise.resolve(profile);
    },
    // get the user permissions (optional)
    getPermissions: () => Promise.resolve(),
    // get the user roles (optional - only for Role-Based Access Control)
    getRoles: () => Promise.resolve(),
};
export const upload=(accountId,file)=>{
    var data = new FormData();
    data.append('file', file);
    data.append('accountID',accountId);
    return fetch(server+'/upload', {
        method: 'POST',
        body: data
    }).then(response=>response.json());
}