import React,{useState} from 'react';
import { TextField, ThemeProvider, Button, Typography, Card, CardContent, CardActions, Box } from '@mui/material';
import { useLogin,useNotify,useTheme,useRedirect  } from 'react-admin';
export const Login = () => {
    const [validation,setValidation]=useState({email:{error:false,message:""},password:{error:false,message:""},snackbar:{open:false,type:"success",message:""}});
    const login = useLogin();
    const notify = useNotify();
    const theme=useTheme()[0]||{};
    const redirect = useRedirect();
    const submit=(event)=>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const newValidation={...validation};
        if(formData.get("email")===""){
            newValidation.email={error:true,message:"Please enter an email address"};
        } else {
            newValidation.email={error:false,message:""};
        }
        if(formData.get("password")===""){
            newValidation.password={error:true,message:"Please enter a password"};
        } else {
            newValidation.retypePassword={error:false,message:""};
        }
        setValidation(newValidation);
        if(!newValidation.email.error && !newValidation.password.error){
            login({ username:formData.get("email"), password:formData.get("password") })
            .catch((error)=>{
                notify(error.message,{type:"error"});
            });
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <Box component="form" onSubmit={submit} noValidate
                sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Card>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <TextField type="email"
                            label="Email:"
                            variant="filled"
                            margin="normal"
                            name="email"
                            error={validation.email.error}
                            helperText={validation.email.message}
                            required fullWidth autoFocus/>
                        <TextField type="password"
                            label="Password:"
                            variant="filled"
                            margin='normal'
                            name="password"
                            error={validation.password.error}
                            helperText={validation.password.message}
                            required fullWidth/>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" type="submit">Login</Button>
                        <Button onClick={(e)=>redirect("/ResetPassword")}>Forgot password?</Button>
                    </CardActions>
                </Card>
            </Box>
        </ThemeProvider>
    )
};